// colors
export const colors = {
  primary: '#ff4081',
  secondary: '#448aff',
  warning: '#ffc409',
  danger: '#eb445a',
};

// Date 
export const singleToDouble = (num: number) => {
  const string = `${num}`;
  if (string.length === 1) {
    return `0${num}`;
  }
  return num;
};

export const toTime = (hour: number, min:number) => {
  const mins = singleToDouble(min);
  switch (hour) {
    case 0:
      return `12:${mins} am`;
    case 1:
      return `1:${mins} am`;
    case 2:
      return `2:${mins} am`;
    case 3:
      return `3:${mins} am`;
    case 4: 
      return `4:${mins} am`;
    case 5:
      return `5:${mins} am`;
    case 6:
      return `6:${mins} am`;
    case 7:
      return `7:${mins} am`;
    case 8:
      return `8:${mins} am`;
    case 9:
      return `9:${mins} am`;
    case 10:
      return `10:${mins} am`;
    case 11:
      return `11:${mins} am`;
    case 12:
      return `12:${mins} pm`;
    case 13:
      return `1:${mins} pm`;
    case 14:
      return `2:${mins} pm`;
    case 15:
      return `3:${mins} pm`;
    case 16:
      return `4:${mins} pm`;
    case 17:
      return `5:${mins} pm`;
    case 18:
      return `6:${mins} pm`;
    case 19:
      return `7:${mins} pm`;
    case 20:
      return `8:${mins} pm`;
    case 21:
      return `9:${mins} pm`;
    case 22:
      return `10:${mins} pm`;
    case 23:
      return `11:${mins} pm`;
    default:
      throw new Error("Invalid Time");
  }
};