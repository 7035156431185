import { collection, Firestore, onSnapshot } from "firebase/firestore";
import { IPaymentMethod } from "interfaces";
import { useEffect } from "react";
import { atom, useSetRecoilState } from "recoil";

export const paymentMethodsState = atom<IPaymentMethod[] | undefined>({
  key: "paymentMethodsState",
  default: undefined,
});

export const paymentMethodsErrorState = atom<Error | undefined>({
  key: "paymentMethodsErrorState",
  default: undefined,
});

export const useGetPaymentMethods = (db: Firestore) => {
  const setPaymentMethods = useSetRecoilState(paymentMethodsState);
  const setPaymentMethodsError = useSetRecoilState(paymentMethodsErrorState);

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "paymentMethods"), {
      next: (snap) => {
        const paymentMethods = snap.docs.map(
          (pmDocs) => ({ id: pmDocs.id, ...pmDocs.data() } as IPaymentMethod)
        );
        setPaymentMethods(paymentMethods);
        setPaymentMethodsError(undefined);
      },
      error: (error) => {
        setPaymentMethods(undefined);
        setPaymentMethodsError(error);
      },
    });

    return () => {
      unsub();
    };
  }, [db, setPaymentMethods, setPaymentMethodsError]);
};
