import { AlertInput, IonIcon, useIonAlert } from "@ionic/react";
import { setLine } from "apis";
import { SlotShell } from "components";
import { Break, Book, IUser, IQueue, IProvider, BookType } from "interfaces";
import { peopleOutline, personOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { Firestore } from "firebase/firestore";

import "./slot.css";

const Slot: React.FC<{
  provider: IProvider;
  db: Firestore;
  queues: IQueue[];
  queue: IQueue;
  user: IUser;
  slot: Break | Book;
  index: number;
  pickSlotIsActive: boolean;
}> = ({ provider, queues, queue, user, slot, index, pickSlotIsActive, db }) => {
  const [ghostSlots, setGhostSlots] = useState<null | string[]>();
  const [loading, setLoading] = useState(false);
  const [selectedService, setSelectedService] = useState<string>();

  const [presentAlert] = useIonAlert();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function generateInviteSlots() {
    if (slot.bookType === "break") return null;
    if (!slot.invite) return null;

    const ghostSlots: string[] = [];
    if (
      queue.line.some((q) =>
        q.bookType === "break"
          ? false
          : q.invite //@ts-ignore
          ? q.invite.count > slot.invite.count
          : false
      )
    )
      for (let i = 0; i < slot.invite.numOfPeople - slot.invite.count; i++) {
        ghostSlots.push("");
      }

    return ghostSlots;
  }

  async function setSlot(index: number) {
    const confirmation = confirm(`Book the #${index + 2} slot?`);

    if (confirmation) {
      const line = queue.head.line.filter((q) => q !== user.id);
      line.splice(index + 1, 0, user.id);

      setLoading(true);

      if (provider.services.length === 1)
        await setLine({
          db,
          customerId: user.id,
          queue,
          provider,
          line,
          data: {
            timestamps: [],
            bookType: BookType.SLOT,
            hasStarted: false,
            customerId: user.id,
            date: queue.head.date,
            invite: null,
            party: {
              isApproved: true,
              numOfPeople: 1,
            },
            serviceId: provider.services[0].id,
            timeJoined: new Date(),
          },
        });
      else
        presentAlert({
          header: "Select a service",
          buttons: [
            { text: "Cancel", role: "cancel" },
            {
              text: "Get in line",
              role: "confirm",
              handler: async () => {
                if (selectedService)
                  await setLine({
                    db,
                    customerId: user.id,
                    queue,
                    provider,
                    line,
                    data: {
                      timestamps: [],
                      bookType: BookType.SLOT,
                      hasStarted: false,
                      customerId: user.id,
                      date: queue.head.date,
                      invite: null,
                      party: {
                        isApproved: true,
                        numOfPeople: 1,
                      },
                      serviceId: selectedService,
                      timeJoined: new Date(),
                    },
                  });
              },
            },
          ],
          inputs: provider.services.map<AlertInput>((service) => ({
            type: "radio",
            label: service.title,
            value: service.id,
            handler: (input) => setSelectedService(input.value),
          })),
        });
      setLoading(false);
    }
  }

  useEffect(() => {
    setGhostSlots(generateInviteSlots());
  }, [generateInviteSlots]);

  return (
    <>
      {slot.bookType === "break" ? (
        <>
          <SlotShell empty={false} label={""}>
            <div>
              <h4 style={{ margin: 0 }}>Break({slot.mins} mins)</h4>
            </div>
          </SlotShell>
        </>
      ) : (
        <>
          <SlotShell
            color={user.id === slot.id ? "secondary" : undefined}
            empty={false}
          >
            <div
              className="slot-container"
              style={
                slot.party.numOfPeople > 1
                  ? { marginTop: "2.5rem", marginBottom: "2.5rem" }
                  : {}
              }
            >
              <p>{index + 1}</p>
              {slot.party.numOfPeople > 1 ? (
                <IonIcon style={{ marginRight: "15px" }} icon={peopleOutline} />
              ) : (
                <IonIcon style={{ marginRight: "15px" }} icon={personOutline} />
              )}
              <p>
                {user.id === slot.id && "You • "}
                {slot.service.totalEstMins * slot.party.numOfPeople} mins
              </p>
            </div>
          </SlotShell>
          {pickSlotIsActive &&
            index >= 2 &&
            index < queue.line.length - 2 &&
            queue.line[index + 1].bookType !== "slot" &&
            slot.bookType !== "slot" && (
              <SlotShell
                color="warning"
                empty
                label="empty V.I.P. slot"
                button
                disabled={loading}
                onClick={() => setSlot(index)}
              />
            )}
          {ghostSlots &&
            ghostSlots.map(() => (
              <SlotShell transparent empty={false}>
                <div>
                  <h3>Reserved</h3>
                </div>
              </SlotShell>
            ))}
        </>
      )}
    </>
  );
};

export default Slot;
