import { IUser } from "interfaces";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { onSnapshot, doc, FirestoreError } from "firebase/firestore";
import { auth, db } from "../App";
import { useEffect } from "react";
import { authState } from "./authState";

export const userState = atom<IUser | null | undefined>({
  key: "user",
  default: undefined,
});
export const userErrorState = atom<FirestoreError | undefined>({
  key: "userError",
  default: undefined,
});

export const useGetUser = () => {
  const setUser = useSetRecoilState(userState);
  const setUserError = useSetRecoilState(userErrorState);
  const authStatus = useRecoilValue(authState);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "users", auth.currentUser?.uid || ""), {
      next: (user) => {
        if (auth.currentUser) {
          const userData = user.exists()
            ? ({ id: auth.currentUser.uid, ...user.data() } as IUser)
            : null;
          setUser(userData);
          setUserError(undefined);
        } else {
          setUser(null);
          setUserError(undefined);
        }
      },
      error: (error) => {
        setUser(undefined);
        setUserError(error);
      },
    });

    return () => unsub();
  }, [setUser, setUserError, authStatus]);
};
