import React from "react";
import { IonItem, IonText } from "@ionic/react";
import "./slot.css";
import { colors } from "utilities";

export interface SlotShell {
  transparent?: boolean;
  children?: React.ReactNode;
  color?: "primary" | "secondary" | "warning" | "danger";
  empty: boolean;
  button?: boolean;
  onClick?: () => void;
  label?: string;
  disabled?: boolean
}

export const SlotShell: React.FC<SlotShell> = ({
  transparent,
  empty,
  children,
  color,
  button,
  onClick,
  label,
  disabled,
}) => {
  if (empty) {
    const border = {
      borderRadius: "1rem",
      //@ts-ignore
      border: `3px ${colors[color]} solid`,
    };
    return (
      <IonItem
        onClick={onClick}
        style={border}
        className="empty"
        button={button}
        disabled={disabled}
        lines="none"
      >
        <IonText color={color}>
          <h4 style={{ margin: 0 }}>{label}</h4>
        </IonText>
      </IonItem>
    );
  }

  return (
    <IonItem
      style={{ opacity: transparent ? 0.3 : 1}}
      className="slotShell"
      color={color}
      lines="none"
    >
      {children}
    </IonItem>
  );
};
