import { GeoPoint } from "firebase/firestore";
import { IPaymentMethod } from "./PaymentMethod";
import { IService } from "./Service";

export enum Day {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

export interface Time {
  hour: number;
  mins: number;
}

export interface Hours {
  from: Time;
  to: Time;
}
export interface BreakTime {
  duration: number;
  time: Time;
}

//Main Interface
export interface IProvider {
  zoneOffset: {
    offset: number;
    zone: string;
  };
  token: string;
  isComplete: boolean;
  id: string;
  profilePic: string;
  name: string;
  email: string;
  phone: {
    formatted: string;
    forUse: string;
  };
  description: string;
  categories: string[];
  address: {
    street: string;
    suite: string;
    city: string;
    state: string;
    zip: string;
    coords: GeoPoint;
  };
  paymentMethodsIds: string[];
  paymentMethods: IPaymentMethod[];
  hours: [
    Hours | null,
    Hours | null,
    Hours | null,
    Hours | null,
    Hours | null,
    Hours | null,
    Hours | null
  ];
  break: [
    BreakTime | null,
    BreakTime | null,
    BreakTime | null,
    BreakTime | null,
    BreakTime | null,
    BreakTime | null,
    BreakTime | null
  ];
  daysCanBookAhead: number;
  services: IService[];
  isOpen: boolean;
  currLineTime: number;
}

//DTO
export interface ProviderDTO {
  isComplete: boolean;
  profilePic: string;
  name: string;
  email: string;
  description: string;
  categories: string[];
  address: {
    street: string;
    suite: string;
    city: string;
    state: string;
    zip: string;
    coords: GeoPoint;
  };
  paymentMethodsIds: string[];
  hours: {
    0: Hours | null;
    1: Hours | null;
    2: Hours | null;
    3: Hours | null;
    4: Hours | null;
    5: Hours | null;
    6: Hours | null;
  };
  break: {
    0: BreakTime | null;
    1: BreakTime | null;
    2: BreakTime | null;
    3: BreakTime | null;
    4: BreakTime | null;
    5: BreakTime | null;
    6: BreakTime | null;
  };
  daysCanBookAhead: number;
  isOpen: boolean;
  currLineTime: number;
  phone: {
    formatted: string;
    forUse: string;
  };
}
