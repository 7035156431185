/* eslint-disable turbo/no-undeclared-env-vars */
import { useEffect, useState, useRef } from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonContent,
  IonIcon,
  IonLabel,
  IonNav,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
  homeOutline,
  home,
  bookmarksOutline,
  bookmarks,
  personCircleOutline,
  personCircle,
} from "ionicons/icons";
import { initializeApp, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  getAuth,
  initializeAuth,
  indexedDBLocalPersistence,
} from "firebase/auth";
import {getAnalytics} from "firebase/analytics"
import Home from "./pages/Home/Home";
import Bookings from "./pages/Bookings/Bookings";
import Profile from "./pages/Profile/Profile";
import Details from "./pages/Details/Details";
import { Landing } from "./pages/Landing/Landing";
import { authState, useAuth } from "./recoil/authState";
import { useRecoilValue } from "recoil";
import { userState } from "./recoil/userState";
import { Loading } from "components";
import Calendar from "./pages/Calendar/Calendar";
import { Capacitor } from "@capacitor/core";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

const firebaseConfig = process.env.NODE_ENV === "development" ? {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
} : {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = !Capacitor.isNativePlatform()
  ? getAuth(app)
  : initializeAuth(app, {
      persistence: indexedDBLocalPersistence,
    });
export const analytics = getAnalytics(app);

setupIonicReact();

const App: React.FC = () => {
  const authUser = useRecoilValue(authState);
  const user = useRecoilValue(userState);
  const [selectedTab, onSelectChange] = useState<string>();

  async function getSelectedTab() {
    //@ts-ignore
    onSelectChange(await document.getElementById("tabs")?.selectedTab);
  }

  useAuth();

  useEffect(() => {
    getSelectedTab();
  }, []);

  if (authUser === undefined || user === undefined) {
    return (
      <IonApp>
        <IonContent>
          <Loading />
        </IonContent>
      </IonApp>
    );
  }

  if (authUser === null || user === null) {
    return (
      <IonApp>
        <IonNav root={() => <Landing />}></IonNav>
      </IonApp>
    );
  }

  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            {/* Separate pages */}
            <Route exact path="/details/:id" component={Details} />
            <Route exact path="/details/:id/calendar" component={Calendar} />

            {/* Tab pages */}
            <Route path="/home">
              <Home />
            </Route>
            <Route path="/bookings">
              <Bookings />
            </Route>
            <Route path="/profile">
              <Profile />
            </Route>

            {/* Redirects */}
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
          </IonRouterOutlet>
          <IonTabBar id="tabs" slot="bottom">
            <IonTabButton
              onClick={() => onSelectChange("home")}
              tab="home"
              href="/home"
            >
              <IonIcon icon={selectedTab === "home" ? home : homeOutline} />
              <IonLabel>Home</IonLabel>
            </IonTabButton>
            <IonTabButton
              onClick={() => onSelectChange("bookings")}
              tab="bookings"
              href="/bookings"
            >
              <IonIcon
                icon={selectedTab === "bookings" ? bookmarks : bookmarksOutline}
              />
              <IonLabel>Bookings</IonLabel>
            </IonTabButton>
            <IonTabButton
              onClick={() => onSelectChange("profile")}
              tab="profile"
              href="/profile"
            >
              <IonIcon
                icon={
                  selectedTab === "profile" ? personCircle : personCircleOutline
                }
              />
              <IonLabel>Profile</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
