import {
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonPage,
  useIonRouter,
} from "@ionic/react";
import { updateDoc, doc } from "firebase/firestore";
import { Button, Header, Loading, Modal } from "components";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { auth, db } from "../../App";
import { userState } from "../../recoil/userState";
import "./profile.css";

const Profile = () => {
  const userData = useRecoilValue(userState);
  const navigation = useIonRouter();

  const [loading, setLoading] = useState(false);
  const [nameModal, setNameModal] = useState(false);
  const [name, setName] = useState(userData?.name);
  const [emailModal, setEmailModal] = useState(false);
  const [email, setEmail] = useState(userData?.email);

  if (!userData) return <Loading />;

  return (
    <IonPage>
      <Header label="Profile settings" />
      <IonContent>
        <IonList>
          <IonItem
            lines="full"
            detail
            button
            onClick={() => setNameModal(true)}
          >
            <IonLabel>
              <p>Name</p>
              <h4>{userData.name}</h4>
            </IonLabel>
          </IonItem>
          {/* <IonItem
            lines="full"
            detail
            button
            onClick={() => console.log("phone")}
          >
            <IonLabel>
              <p>Phone</p>
              <h4>{userData?.phone}</h4>
            </IonLabel>
          </IonItem> */}
          <IonItem
            lines="full"
            detail
            button
            onClick={() => setEmailModal(true)}
          >
            <IonLabel>
              <p>Email</p>
              <h4>{userData.email}</h4>
            </IonLabel>
          </IonItem>
        </IonList>

        <Button
          className="logOutBtn"
          expand
          label="Logout"
          onClick={() => {
            auth.signOut();
            navigation.push("/", "root");
          }}
          color="primary"
        />
        <Modal isOpen={nameModal} setOpen={setNameModal} title="Update name">
          <IonItem
            className={`input ${
              (name as string).length === 0 && "ion-invalid"
            }`}
          >
            <IonInput
              placeholder="Enter name"
              value={name}
              onIonInput={(input) => setName(input.target.value as string)}
            ></IonInput>
            <IonNote slot="error">Please enter your name</IonNote>
          </IonItem>
          <Button
            color="primary"
            label="Update"
            onClick={async () => {
              if (name?.length) {
                setLoading(true);
                await updateDoc(doc(db, "users", userData.id), { name });
                setNameModal(false);
                setLoading(false);
              }
            }}
            expand
            loading={loading}
          />
        </Modal>
        <Modal isOpen={emailModal} setOpen={setEmailModal} title="Update email">
          <IonItem
            className={`input ${
              !email?.match(
                /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/
              ) && "ion-invalid"
            }`}
          >
            <IonInput
              placeholder="Enter email"
              value={email}
              onIonInput={(input) => setEmail(input.target.value as string)}
            ></IonInput>
            <IonNote slot="error">Please enter a valid email</IonNote>
          </IonItem>
          <Button
            color="primary"
            label="Update"
            onClick={async () => {
              if (
                email?.match(
                  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/
                )
              ) {
                setLoading(true);
                await updateDoc(doc(db, "users", userData.id), { email });
                setEmailModal(false);
                setLoading(false);
              }
            }}
            expand
            loading={loading}
          />
        </Modal>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
