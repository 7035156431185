import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useRef } from "react";
import "./modal.css"

export interface ModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>; 
  title: string;
  children: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({ isOpen, setOpen, title, children }) => {
  const modalRef = useRef<HTMLIonModalElement>(null);
  function dismiss() {
    setOpen(false)
  }
  return (
    <>
      <IonModal isOpen={isOpen} ref={modalRef}>
        <IonContent>
          <IonToolbar>
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={dismiss} size="small" fill="clear">
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <div className="wrapper">
            {children}
          </div>
        </IonContent>
      </IonModal>
    </>
  );
};
