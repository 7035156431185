import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonSlide,
  IonSlides,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  FirebaseAuthentication,
  SignInWithPhoneNumberResult,
} from "@capacitor-firebase/authentication";
import { Capacitor } from "@capacitor/core";
import { arrowBackOutline } from "ionicons/icons";
import {
  RecaptchaVerifier,
  ConfirmationResult,
  PhoneAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import { Button } from "components";
import { E164Number } from "libphonenumber-js/types";
import { useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./auth.css";
import { auth, db } from "../../App";
import { signInWithPhoneNumber } from "firebase/auth";
import { doc, getDoc, setDoc, collection } from "firebase/firestore";

const Auth = () => {
  const [tabIndex, setTabIndex] = useState<any>(0);

  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<E164Number>();
  const [phoneNumberError, setPhoneNumberError] = useState<any>();

  const [nativeResult, setNativeResult] =
    useState<SignInWithPhoneNumberResult>();

  const [result, setResult] = useState<ConfirmationResult>();
  const [authCode, setAuthCode] = useState<any>();
  const [authCodeError, setAuthCodeError] = useState<any>();
  const slideRef = useRef<HTMLIonSlidesElement>();
  const [userId, setUserId] = useState<string>("");

  const [name, setName] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [nameAndEmailError, setNameAndEmailError] = useState(false);

  function generateRecap() {
    //@ts-ignore
    window.recap = new RecaptchaVerifier("recap-container", {}, auth);
  }

  const onSubmit = async () => {
    setLoading(true);
    setPhoneNumberError(undefined);
    if (!Capacitor.isNativePlatform()) {
      generateRecap();
      //@ts-ignore
      let recap = window.recap;
      if (phoneNumber && recap) {
        try {
          const result = await signInWithPhoneNumber(
            auth,
            phoneNumber.toString(),
            recap
          );
          setResult(result);
          setLoading(false);
          slideRef.current?.slideNext();
        } catch (error) {
          //@ts-ignore
          document.getElementById("recap-container").innerHTML = "";
          setPhoneNumberError(error);
          setLoading(false);
        }
      }
    } else {
      try {
        if (phoneNumber) {
          const result = await FirebaseAuthentication.signInWithPhoneNumber({
            phoneNumber: phoneNumber.toString(),
          });

          setNativeResult(result);
          setLoading(false);
          slideRef.current?.slideNext();
        }
      } catch (error) {
        setPhoneNumberError(error);
        setLoading(false);
      }
    }
  };

  const onConfirm = async () => {
    setAuthCodeError(undefined);
    setLoading(true);
    if (!Capacitor.isNativePlatform()) {
      try {
        const user = await result?.confirm(authCode);
        if (user) {
          setUserId(user.user.uid);
          const userDoc = await getDoc(doc(db, "users", user.user.uid));
          if (!userDoc.exists()) slideRef.current?.slideNext();
        }
        setLoading(false);
      } catch (error) {
        setAuthCodeError(error);
        setLoading(false);
      }
    } else {
      try {
        const credential = PhoneAuthProvider.credential(
          nativeResult?.verificationId || "",
          authCode
        );
        const user = await signInWithCredential(auth, credential);

        if (user) {
          setUserId(user.user.uid);
          const userDoc = await getDoc(doc(db, "users", user.user.uid));
          if (!userDoc.exists()) slideRef.current?.slideNext();
        }
        setLoading(false);
      } catch (error) {
        setAuthCodeError(error);
        setLoading(false);
      }
    }
  };

  const onRegister = async () => {
    setNameAndEmailError(false);
    setLoading(true);
    if (
      email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) &&
      name.length
    ) {
      try {
        await setDoc(doc(db, "users", userId), {
          name,
          email: email.toLowerCase(),
          phone: phoneNumber,
          line: [],
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      setNameAndEmailError(true);
      setLoading(false);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {tabIndex === 1 && (
              <IonButton onClick={() => slideRef.current?.slidePrev()}>
                <IonIcon slot="icon-only" icon={arrowBackOutline}></IonIcon>
              </IonButton>
            )}
          </IonButtons>
          <IonTitle>Sign in/Sign up</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonSlides
          onIonSlideDidChange={async (e) =>
            setTabIndex(await e.target.getActiveIndex())
          }
          options={{
            allowTouchMove: false,
          }}
          ref={slideRef as React.Ref<HTMLIonSlidesElement>}
          style={{ height: "100%" }}
        >
          <IonSlide>
            <div>
              {phoneNumberError && (
                <IonText color="danger">
                  <p>Please enter vaild phone number.</p>
                </IonText>
              )}
              <IonItem lines="full">
                <IonLabel position="stacked">Enter your phone number:</IonLabel>
                <PhoneInput
                  placeholder="(000) 000-0000"
                  className="phone-input"
                  defaultCountry="US"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                />
              </IonItem>
              <Button
                expand
                loading={loading}
                className="btn"
                label="Submit"
                color="primary"
                onClick={onSubmit}
              />
              <div id="recap-container"></div>
            </div>
          </IonSlide>

          <IonSlide>
            <div>
              {authCodeError && (
                <IonText color="danger">
                  <p>Please try again.</p>
                </IonText>
              )}
              <IonItem lines="full">
                <IonLabel position="floating">Passcode</IonLabel>
                <IonInput
                  value={authCode}
                  onIonInput={(e) => setAuthCode(e.target.value)}
                  type="number"
                />
              </IonItem>
              <Button
                expand
                loading={loading}
                className="btn"
                label="Continue"
                color="primary"
                onClick={onConfirm}
              />
            </div>
          </IonSlide>

          <IonSlide>
            <div>
              {nameAndEmailError && (
                <IonText color="danger">
                  <p>Please enter valid name & email.</p>
                </IonText>
              )}
              <IonItem lines="full">
                <IonLabel position="floating">Name</IonLabel>
                <IonInput
                  value={name}
                  onIonInput={(e) => setName(e.target.value)}
                />
              </IonItem>
              <IonItem lines="full">
                <IonLabel position="floating">Email</IonLabel>
                <IonInput
                  value={email}
                  onIonInput={(e) => setEmail(e.target.value)}
                  type="email"
                />
              </IonItem>
              <Button
                expand
                loading={loading}
                className="btn"
                label="Sign up"
                color="primary"
                onClick={onRegister}
              />
            </div>
          </IonSlide>
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default Auth;
