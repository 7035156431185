import { IBooking, ICategory, IProvider } from "interfaces";
import { useEffect } from "react";
import { atom, useSetRecoilState } from "recoil";
import {
  onSnapshot,
  collection,
  FirestoreError,
  query,
  where,
  getDocs,
  QuerySnapshot,
  DocumentData,
} from "firebase/firestore";
import { auth, db } from "../App";

export const bookingsState = atom<IBooking[] | undefined>({
  key: "bookings",
  default: undefined,
});

export const bookingsErrorState = atom<FirestoreError | undefined>({
  key: "bookingsError",
  default: undefined,
});

export const useGetBookings = () => {
  const setBookings = useSetRecoilState(bookingsState);
  const setBookingsError = useSetRecoilState(bookingsErrorState);

  useEffect(() => {
    onSnapshot(
      query(
        collection(db, "bookings"),
        where("customerId", "==", auth.currentUser?.uid)
      ),
      {
        next: async (bookings) => {
          const categoriesIds = bookings.docs.map(
            (snap) => snap.data().categoryId
          );
          const providersIds = bookings.docs.map(
            (snap) => snap.data().providerId
          );
          let categories: QuerySnapshot<DocumentData>;
          let providers: QuerySnapshot<DocumentData>;
          if (categoriesIds.length && providersIds.length) {
            categories = await getDocs(
              query(
                collection(db, "categories"),
                where("__name__", "in", categoriesIds)
              )
            );
            providers = await getDocs(
              query(
                collection(db, "providers"),
                where("__name__", "in", providersIds)
              )
            );
          }
          const bookingsList = bookings.docs.map((doc) => {
            const category = categories.docs.find(
              (snap) => snap.id === doc.data().categoryId
            );
            const provider = providers.docs.find(
              (snap) => snap.id === doc.data().providerId
            );
            return {
              id: doc.id,
              ...doc.data(),
              category: { id: category?.id, ...category?.data() } as ICategory,
              provider: { id: provider?.id, ...provider?.data() } as IProvider,
            } as IBooking;
          });
          setBookings(bookingsList);
          setBookingsError(undefined);
        },
        error: (error) => {
          setBookings(undefined);
          setBookingsError(error);
        },
      }
    );
  }, [setBookings, setBookingsError]);
};
