import moment from "moment";
import {
  BookDTO,
  BreakDTO,
  BreakTime,
  BreakType,
  Hours,
  IProvider,
  IQueue,
  IService,
  QueueDTO,
} from "interfaces";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";

export interface GILProps {
  db: Firestore;
  queues: IQueue[];
  queue: IQueue | string;
  provider: IProvider;
  data: BookDTO | BreakDTO;
  customerId?: string;
}

export const getInLine = async ({
  db,
  queues,
  queue,
  provider,
  data,
  customerId,
}: GILProps) => {
  const date = moment(typeof queue === "string" ? queue : queue.head.date);
  if (!customerId) {
    if (typeof queue === "string") {
      const queueDTO: QueueDTO = {
        breakScheduled: false,
        autoClose: {
          wasActivated: false,
          wasNotified: false,
        },
        line: [],
        currLineTime: 0,
        date: queue,
      };
      await setDoc(
        doc(db, "providers", provider.id, "queues", queue),
        queueDTO
      );
      queue = { head: { id: queue, ...queueDTO }, line: [] } as IQueue;
    }
    const userDoc = await addDoc(
      collection(db, "providers", provider.id, "queues"),
      data
    );
    await updateDoc(
      doc(db, "providers", provider.id, "queues", (queue as IQueue).head.id),
      { line: arrayUnion(userDoc.id) }
    );
  } else {
    const findExistingBook = queues.find((q) =>
      q.line.some((r) => r.id === customerId)
    );
    if (findExistingBook)
      await updateDoc(
        doc(db, "providers", provider.id, "queues", findExistingBook.head.id),
        { line: arrayRemove(customerId) }
      );
    if (typeof queue === "string" || queue.line.length === 0) {
      if (typeof queue === "string") {
        const queueDTO: QueueDTO = {
          breakScheduled: false,
          autoClose: {
            wasActivated: false,
            wasNotified: false,
          },
          line: [],
          currLineTime: 0,
          date: queue,
        };
        await setDoc(
          doc(db, "providers", provider.id, "queues", queue),
          queueDTO
        );
        queue = { head: { id: queue, ...queueDTO }, line: [] } as IQueue;
      }
      const placeholderDoc = await addDoc(
        collection(db, "providers", provider.id, "queues"),
        {
          bookType: "break",
          breakType: BreakType.PLACEHOLDER,
          mins: 30,
          date: (queue as IQueue).head.date,
          timeJoined: new Date(),
          frontTimestamp:
            queue.head.date === moment().format().split("T")[0]
              ? new Date()
              : new Date(
                  moment(queue.head.date)
                    .set(
                      "hours",
                      (provider.hours[moment(queue.head.date).day()] as Hours)
                        .from.hour
                    )
                    .set(
                      "minutes",
                      (provider.hours[moment(queue.head.date).day()] as Hours)
                        .from.mins
                    )
                    .format()
                ),
        } as BreakDTO
      );
      await setDoc(
        doc(db, "providers", provider.id, "queues", customerId),
        data
      );
      updateDoc(
        doc(db, "providers", provider.id, "queues", (queue as IQueue).head.id),
        { line: [placeholderDoc.id, customerId] }
      );
    } else {
      const serviceDoc = await getDoc(
        doc(db, "providers", provider.id, "services", (data as BookDTO).serviceId)
      );
      if (
        moment(queue.line[0].frontTimestamp?.toMillis()).add(
          queue.head.currLineTime +
            (serviceDoc.data() as IService).totalEstMins,
          "minutes"
        ) >=
          moment(queue.head.date)
            .set("hours", (provider.break[date.day()] as BreakTime).time.hour)
            .set(
              "minutes",
              (provider.break[date.day()] as BreakTime).time.mins
            ) &&
        !queue.head.breakScheduled
      ) {
        const breakDoc = await addDoc(
          collection(db, "providers", provider.id, "queues"),
          {
            bookType: "break",
            breakType: BreakType.BREAK,
            mins: (provider.break[date.day()] as BreakTime).duration,
            date: queue.head.date,
            timeJoined: new Date(),
          } as BreakDTO
        );
        await setDoc(
          doc(db, "providers", provider.id, "queues", customerId),
          data
        );
        await updateDoc(
          doc(db, "providers", provider.id, "queues", queue.head.id),
          { line: arrayUnion(breakDoc.id, customerId), breakScheduled: true }
        );
      } else {
        await setDoc(
          doc(db, "providers", provider.id, "queues", customerId),
          data
        );
        await updateDoc(
          doc(db, "providers", provider.id, "queues", queue.head.id),
          {
            line: arrayUnion(customerId),
          }
        );
      }
    }
  }
};

export interface SLProps {
  db: Firestore;
  queue: IQueue;
  provider: IProvider;
  data: BookDTO;
  customerId: string;
  line: string[];
}

export const setLine = async ({
  db,
  queue,
  provider,
  data,
  customerId,
  line,
}: SLProps) => {
  await setDoc(doc(db, "providers", provider.id, "queues", customerId), data);
  await updateDoc(doc(db, "providers", provider.id, "queues", queue.head.id), {
    line,
  });
};

export interface LLProps {
  db: Firestore;
  queue: IQueue;
  provider: IProvider;
  customerId: string;
}
export const leaveLine = async ({
  db,
  queue,
  provider,
  customerId,
}: LLProps) => {
  if (queue.line[0].id === customerId && queue.line.length > 1) {
    await updateDoc(
      doc(db, "providers", provider.id, "queues", queue.line[1].id),
      { frontTimestamp: new Date() }
    );
  }
  await updateDoc(doc(db, "providers", provider.id, "queues", queue.head.id), {
    line: arrayRemove(customerId),
  });
  await deleteDoc(doc(db, "providers", provider.id, "queues", customerId));
};
