import { IProvider } from "interfaces";
import { useEffect } from "react";
import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil";
import { db } from "../App";
import { onSnapshot, collection } from "firebase/firestore";

export const providersState = atom<IProvider[] | undefined>({
  key: "providers",
  default: undefined
});

export const providersErrorState = atom<any>({
  key: "providersError",
  default: undefined,
});

export const useGetProviders = () => {
  const setProviders = useSetRecoilState(providersState);
  const setProvidersError = useSetRecoilState(providersErrorState);

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "providers"), {
      next: (providers) => {
        const providersList = providers.docs.map((doc) => {
          return { id: doc.id, ...doc.data() } as IProvider;
        });
        setProviders(providersList);
        setProvidersError(undefined);
      },
      error: (error) => {
        setProviders(undefined);
        setProvidersError(error);
      },
    });
    return () => {
      unsub();
    };
  }, [setProviders, setProvidersError]);
};
