import React, { ReactNode } from "react";
import { IonButton, IonSpinner, IonIcon } from "@ionic/react";

export interface ButtonProps {
  id?: string
  className?: string;
  size?: "small" | "default" | "large";
  label: string | ReactNode;
  color: "primary" | "secondary" | "medium" | "danger";
  onClick: () => void;
  expand?: boolean;
  loading?: boolean;
  disabled?: boolean;
  type?: "submit"
}

export const Button: React.FC<ButtonProps> = ({
  id,
  className,
  label,
  size,
  color,
  onClick,
  expand,
  loading,
  disabled,
  type,
  ...props
}) => {
  const isExpand = expand ? "block" : undefined;
  return (
    <IonButton
      id={id}
      className={className}
      expand={isExpand}
      size={size}
      color={color}
      onClick={onClick}
      disabled={loading || disabled}
      type={type}
      {...props}
    >
      {loading ? <IonSpinner name="crescent" /> : label}
    </IonButton>
  );
};
