import { Timestamp } from "firebase/firestore";
import { IService } from "./Service";
import { IUser, UserDTO } from "./User";

// Objects
export enum BookType {
  MANUAL = "manual",
  CALENDAR = "calendar",
  QUEUE = "queue",
  SLOT = "slot",
}
export enum BreakType {
  PLACEHOLDER = "placeholder",
  BREAK = "break",
  CUSTOM = "custom",
}

export interface Party {
  isApproved: boolean;
  numOfPeople: number;
}
export interface Invite {
  count: number;
  token: string;
  numOfPeople: number;
  timestamp: Timestamp;
}

// Main Interfaces
export interface Head {
  id: string;
  breakScheduled: boolean;
  autoClose: {
    wasActivated: boolean;
    wasNotified: boolean;
  };
  line: string[];
  currLineTime: number;
  date: string;
}
export interface Book {
  id: string;
  frontTimestamp?: Timestamp;
  bookType: BookType;
  isConfirmed?: boolean;
  hasStarted: boolean;
  party: Party;
  invite: Invite | null;
  timeJoined: Timestamp;
  date: string;
  customerId?: string;
  customer: IUser;
  serviceId: string;
  service: IService;
  timestamps: Timestamp[];
}
export interface Break {
  id: string;
  frontTimestamp?: Timestamp;
  bookType: "break";
  breakType: BreakType;
  mins: number;
  date: string;
  timeJoined: Timestamp;
}

export interface IQueue {
  head: Head;
  line: (Break | Book)[];
}

//DTOS
export interface InviteDTO {
  count: number;
  token: string;
  numOfPeople: number;
  timestamp: Date;
}

export interface HeadDTO {
  breakScheduled: boolean;
  autoClose: {
    wasActivated: boolean;
    wasNotified: boolean;
  };
  line: string[];
  currLineTime: number;
  date: string;
}
export interface BookDTO {
  bookType: BookType;
  frontTimestamp?: Date | null;
  isConfirmed?: boolean;
  hasStarted: boolean;
  party: Party;
  invite: InviteDTO | null;
  timeJoined: Date;
  date: string;
  customerId?: string;
  customer?: Partial<UserDTO>;
  serviceId: string;
  timestamps: Date[];
}
export interface BreakDTO {
  bookType: "break";
  frontTimestamp?: Date;
  breakType: BreakType;
  mins: number;
  date: string;
  timeJoined: Date;
}

export type QueueDTO = HeadDTO | BookDTO | BreakDTO;
