import React, { useState } from "react";
import {
  IonContent,
  IonFooter,
  IonNavLink,
  IonPage,
  IonToolbar,
} from "@ionic/react";
import { Button, MehuLetters, MehuLogo } from "components";
import { colors } from "utilities";
import "./landing.css";
import Auth from "../Auth/Auth";

export const Landing = () => {
  return (
    <IonPage>
      <IonContent className="container">
        <div className="landing">
          <MehuLogo fill={colors.primary} width={150} />
          <p>
            <MehuLetters fill={colors.primary} width={150} />
          </p>
        </div>
      </IonContent>
      <IonFooter>
        <IonToolbar style={{ padding: "1rem" }}>
          <IonNavLink routerDirection="forward" component={() => <Auth />}>
            <Button
              expand
              label="Sign in/Sign up"
              color="primary"
              onClick={() => {}}
            />
          </IonNavLink>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};
