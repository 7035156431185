/* eslint-disable @next/next/no-img-element */
import {
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonSkeletonText,
  IonThumbnail,
} from "@ionic/react";
import React from "react";
import { IProvider, IUser } from "interfaces";

import "./miniCard.css";

export interface MiniCardProps {
  customer: IUser;
  provider: IProvider;
  onClick: () => void;
}

export const MiniCard: React.FC<MiniCardProps> = ({
  customer,
  provider,
  onClick,
}) => {
  const minsToHour = Math.round(provider.currLineTime / 60);

  return (
    <IonCard className="mini-card" onClick={onClick} button={true}>
      <img
        className="mini-profilePic"
        src={provider.profilePic}
        alt={`${provider.name}'s profile picture`}
      />
      <IonCardSubtitle className="subtitle">{provider.name}</IonCardSubtitle>
      <IonCardContent>
        {customer.line.some((r) => r === provider.id) ? (
          <IonCardTitle color="secondary">In line</IonCardTitle>
        ) : provider.isOpen ? (
          <>
            <p>estimated wait time:</p>
            <IonCardTitle>
              {provider.currLineTime < 60
                ? `${provider.currLineTime} mins`
                : `${minsToHour} hour${minsToHour > 1 && "s"}`}
            </IonCardTitle>
          </>
        ) : (
          <IonCardTitle color="danger">Closed</IonCardTitle>
        )}
      </IonCardContent>
    </IonCard>
  );
};
