import {
  IonContent,
  IonPage,
  IonText,
  IonIcon,
  IonItemDivider,
  IonLabel,
  useIonRouter,
  useIonAlert,
  AlertInput,
  useIonToast,
} from "@ionic/react";
import { peopleOutline } from "ionicons/icons";
import { Button, DetailsCard, Header, Loading } from "components";
import { useRecoilValue } from "recoil";
import {
  allQueuesState,
  getInLine,
  leaveLine,
  providerState,
  todayQueueState,
  useGetProvider,
} from "apis";
import { RouteComponentProps } from "react-router";
import { db } from "../../App";
import "./details.css";
import Slot from "../../components/Slot/Slot";
import { Ref, useEffect, useRef, useState } from "react";
import { userState } from "../../recoil/userState";
import { BookType, IQueue } from "interfaces";
import GroupModal from "../../components/GroupModal/GroupModal";
import moment from "moment";

const Details: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const navigation = useIonRouter();
  const provider = useRecoilValue(providerState);
  const queue = useRecoilValue(todayQueueState);
  const allQueues = useRecoilValue(allQueuesState);
  const user = useRecoilValue(userState);

  const pageRef = useRef<HTMLIonContentElement>();

  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();

  const [loading, setLoading] = useState(false);
  const [currUserQueue, setCurrUserQueue] = useState(
    allQueues?.find((q) => q.line.some((r) => r.id === user?.id))
  );
  const [pickSlotIsActive, setPickSlotIsActive] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedService, setSelectedService] = useState<string>();
  const [GIL, setGIL] = useState(false);

  async function onGetInLineClick() {
    setLoading(true);
    if (provider && allQueues && queue && user) {
      if (provider.services.length === 1) {
        await getInLine({
          db,
          queues: allQueues,
          provider,
          queue: queue === "empty" ? moment().format().split("T")[0] : queue,
          customerId: user?.id,
          data: {
            timestamps: [],
            bookType: BookType.QUEUE,
            hasStarted: false,
            customerId: user.id,
            date: moment().format().split("T")[0],
            invite: null,
            party: {
              isApproved: true,
              numOfPeople: 1,
            },
            serviceId: provider.services[0].id,
            timeJoined: new Date(),
          },
        });
      } else
        presentAlert({
          header: "Select a service",
          buttons: [
            { text: "Cancel", role: "cancel" },
            {
              text: "Get in line",
              role: "confirm",
              handler: async () => {
                setGIL(true);
                setTimeout(() => pageRef.current?.scrollToBottom(750), 750);
              },
            },
          ],
          inputs: provider.services.map<AlertInput>((service) => ({
            type: "radio",
            label: service.title,
            value: service.id,
            handler: (input) => setSelectedService(input.value),
          })),
        });
      setTimeout(() => pageRef.current?.scrollToBottom(750), 750);
    }
    setLoading(false);
  }

  function onLeaveLineClick() {
    setLoading(true);
    if (provider && user && currUserQueue) {
      const confirmation = confirm("Are you sure you want to leave the line?");

      if (confirmation)
        leaveLine({
          db,
          provider,
          queue: currUserQueue,
          customerId: user.id,
        });
    }
    setLoading(false);
  }

  useGetProvider(db, match.params.id);
  useEffect(() => {
    setCurrUserQueue(
      allQueues?.find((q) => q.line.some((r) => r.id === user?.id))
    );
  }, [allQueues, user?.id]);
  useEffect(() => {
    if (typeof queue === "object")
      if (queue.line.length > 7)
        presentToast({
          position: "top",
          message:
            "For an additional fee, jump the line with a limited VIP slot.",
          duration: 60000,
          buttons: [
            {
              text: "Check out slots",
              role: "slots",
              handler: () => setPickSlotIsActive(true),
            },
            {
              text: "Dismiss",
              role: "cancel",
            },
          ],
        });
  }, [presentToast, queue]);
  useEffect(() => {
    if (selectedService && provider && allQueues && user && queue && GIL) {
      getInLine({
        db,
        queues: allQueues,
        provider,
        queue: queue === "empty" ? moment().format().split("T")[0] : queue,
        customerId: user.id,
        data: {
          timestamps: [],
          bookType: BookType.QUEUE,
          hasStarted: false,
          customerId: user.id,
          date: moment().format().split("T")[0],
          invite: null,
          party: {
            isApproved: true,
            numOfPeople: 1,
          },
          serviceId: selectedService,
          timeJoined: new Date(),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GIL]);

  if (!provider || !queue || !user || !allQueues) return <Loading />;

  return (
    <IonPage>
      <Header label="Details" />
      <IonContent ref={pageRef as Ref<HTMLIonContentElement>}>
        <DetailsCard provider={provider} showEdit={false} db={db}>
          {currUserQueue ? (
            <>
              {currUserQueue.head.date === moment().format().split("T")[0] ? (
                <>
                  <p style={{ marginBottom: "0px" }}>
                    your estimated wait time:
                  </p>
                  <IonText color="secondary">
                    <h1 style={{ marginTop: "0px" }}>
                      {(queue as IQueue).line.reduce((prev, curr, i, arr) => {
                        if (i >= arr.findIndex((r) => r.id === user.id)) {
                          return prev;
                        }
                        return (
                          prev +
                          (curr.bookType === "break"
                            ? curr.mins
                            : curr.service.totalEstMins *
                              curr.party.numOfPeople)
                        );
                      }, 0)}{" "}
                      mins
                    </h1>
                  </IonText>
                </>
              ) : (
                <>
                  <p style={{ marginBottom: "0px" }}>you are booked for:</p>
                  <h1 style={{ marginTop: "0px" }}>
                    {moment(currUserQueue.head.date).format("MMM Do")}
                  </h1>
                </>
              )}
            </>
          ) : provider.isOpen ? (
            <>
              <p style={{ marginBottom: "0px" }}>estimated wait time:</p>
              <h1 style={{ marginTop: "0px" }}>
                {queue === "empty" ? 0 : queue.head.currLineTime} mins
              </h1>
            </>
          ) : (
            <IonText color="danger">
              <h1>Closed</h1>
            </IonText>
          )}
          <div className="btns">
            {!allQueues?.some((q) => q.line.some((r) => r.id === user.id)) ? (
              <>
                <Button
                  className="btn"
                  expand
                  color="primary"
                  onClick={onGetInLineClick}
                  label="Get in line"
                  disabled={!provider.isOpen || loading}
                  loading={loading}
                  size="small"
                />
                <Button
                  color="primary"
                  onClick={() => setOpen(true)}
                  label={<IonIcon icon={peopleOutline} />}
                  disabled={!provider.isOpen || loading}
                  size="small"
                />
              </>
            ) : (
              <div style={{ width: "100%" }}>
                <Button
                  expand
                  color="medium"
                  label="Leave line"
                  onClick={onLeaveLineClick}
                  loading={loading}
                  size="small"
                />
              </div>
            )}
          </div>
          {provider.daysCanBookAhead ? (
            <Button
              expand
              label="Future lines"
              onClick={() =>
                navigation.push(`${match.params.id}/calendar`, "forward")
              }
              color="secondary"
              size="small"
            />
          ) : null}
        </DetailsCard>
        <br />
        <hr
          style={{
            backgroundColor: "#333",
            height: 1,
            margin: "0px",
          }}
        />
        <IonItemDivider>
          <IonLabel>Line</IonLabel>
        </IonItemDivider>
        <div className="line-container">
          {queue === "empty" || !queue.line.length ? (
            <p style={{ textAlign: "center" }}>No one in line yet.</p>
          ) : (
            queue.line.map((slot, index) => (
              <Slot
                provider={provider}
                queues={allQueues}
                db={db}
                key={index}
                queue={queue}
                index={index}
                pickSlotIsActive={pickSlotIsActive}
                slot={slot}
                user={user}
              />
            ))
          )}
        </div>
        <GroupModal
          open={open}
          setOpen={setOpen}
          provider={provider}
          allQueues={allQueues}
          user={user}
          queue={queue}
          bookType={BookType.QUEUE}
          pageRef={pageRef}
        />
      </IonContent>
    </IonPage>
  );
};

export default Details;
