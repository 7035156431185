import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  useIonRouter,
} from "@ionic/react";
import { Header, Loading } from "components";
import moment from "moment";
import React from "react";
import { useRecoilValue } from "recoil";
import {
  bookingsErrorState,
  bookingsState,
  useGetBookings,
} from "../../recoil/bookingsState";

const Bookings = () => {
  const navigation = useIonRouter();

  const bookings = useRecoilValue(bookingsState);
  const bookingsError = useRecoilValue(bookingsErrorState);

  useGetBookings();

  if (!bookings) return <Loading />;

  return (
    <IonPage>
      <Header label="Bookings" />
      <IonContent>
        <IonList>
          <IonListHeader>
            <IonLabel>Past bookings</IonLabel>
          </IonListHeader>
          {bookings.length === 0 ? (
            <p style={{ textAlign: "center" }}>No completed bookings yet</p>
          ) : (
            bookings.map((booking) => {
              return (
                <IonItem
                  key={booking.id}
                  detail
                  button
                  onClick={() =>
                    navigation.push(`/details/${booking.providerId}`)
                  }
                >
                  <IonLabel>
                    <h2>{booking.provider.name}</h2>
                    <p>{booking.category.title}</p>
                  </IonLabel>
                  <p slot="end">
                    {moment(booking.completed.toMillis()).format("M/DD/YY")}
                  </p>
                </IonItem>
              );
            })
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Bookings;
