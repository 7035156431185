import {
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { Loading, MehuLogo, MiniCard } from "components";
import { useEffect } from "react";
import { colors } from "utilities";
import "../../global.css";
import { useRecoilValue } from "recoil";
import {
  providersErrorState,
  providersState,
  useGetProviders,
} from "../../recoil/providersState";

import "./home.css";
import { categoriesErrorState, categoriesState, useGetCategories } from "apis";
import { db } from "../../App";
import { useGetUser, userState } from "../../recoil/userState";

const Home = () => {
  const navigation = useIonRouter();

  const providers = useRecoilValue(providersState);
  const providersError = useRecoilValue(providersErrorState);
  const categories = useRecoilValue(categoriesState);
  const categoriesError = useRecoilValue(categoriesErrorState);
  const user = useRecoilValue(userState)

  useGetUser();
  useGetProviders();
  useGetCategories(db);

  if (!providers || !categories || !user) return <Loading />;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <MehuLogo fill={colors.primary} width={20} />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem lines="full">
          <IonSelect placeholder="Select category">
            {categories.map((category) => (
              <IonSelectOption key={category.id} value={category.id}>
                {category.title}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <div className="minicard-wrapper">
          {providers.map((provider) => (
            <MiniCard
              key={provider.id}
              customer={user}
              provider={provider}
              onClick={() =>
                navigation.push(`/details/${provider.id}`, "forward")
              }
            />
          ))}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
