import { atom, selector, useSetRecoilState } from "recoil";
import { ICategory } from "interfaces";
import { collection, doc, Firestore, onSnapshot } from "firebase/firestore";
import { useEffect } from "react";

export const categoriesState = atom<ICategory[] | undefined>({
  key: "categoriesState",
  default: undefined,
});

export const categoriesErrorState = atom<any>({
  key: "categoriesErrorState",
  default: undefined,
});

export const useGetCategories = (db: Firestore) => {
  const setCategories = useSetRecoilState(categoriesState);
  const setCategoriesError = useSetRecoilState(categoriesErrorState);

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "categories"), {
      next: (categoriesDocs) => {
        const categories = categoriesDocs.docs.map(
          (snap) => ({ id: snap.id, ...snap.data() } as ICategory)
        );

        setCategories(categories);
        setCategoriesError(undefined);
      },
      error: (err) => {
        setCategories(undefined);
        setCategoriesError(err);
      },
    });

    return () => unsub();
  }, [db, setCategories, setCategoriesError]);
};
