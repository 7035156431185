import React from "react";

export interface MehuLettersProps {
  fill: string;
  width: number;
}

export const MehuLetters: React.FC<MehuLettersProps> = ({ fill, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 319 84"
      fill={fill}
    >
      <path
        d="M67.995 47.0565C67.995 44.9007 68.2531 43.1822 67.9279 41.5821C67.5448 39.6969 66.6566 37.9144 65.9874 36.0873C64.8305 37.496 63.2371 38.7288 62.5875 40.3417C58.3094 50.9646 54.2342 61.6692 50.094 72.3477C49.5537 73.7413 48.8704 75.1003 48.5123 76.5398C47.5464 80.4221 45.6215 82.1291 41.1785 82.0656C36.8895 82.0044 35.4842 80.0087 34.1838 76.6074C29.4416 64.2039 24.5539 51.8547 19.5541 39.5529C18.9756 38.1295 17.3525 37.1307 16.2137 35.935C15.5415 37.4328 14.3062 38.9231 14.2861 40.4296C14.1261 52.4235 14.076 64.4223 14.265 76.4154C14.3275 80.3784 13.3826 82.1121 8.97642 81.9698C0.812365 81.7062 0.803601 81.9616 0.803501 73.7789C0.803229 51.2864 0.913859 28.7929 0.71356 6.3022C0.671726 1.60489 1.88348 -0.190776 6.87104 0.0319609C19.2091 0.582962 16.0056 -1.75266 20.5093 9.38052C26.5479 24.3082 32.3371 39.3368 38.3596 54.2711C38.9758 55.7992 40.4529 56.98 41.5305 58.322C42.6004 56.9727 44.0683 55.782 44.679 54.2497C51.1928 37.907 57.6934 21.5564 63.8919 5.09283C65.283 1.39799 66.9957 -0.0962044 71.0561 0.0400257C82.9049 0.437562 81.5544 -1.50197 81.5798 10.8564C81.6245 32.5158 81.5992 54.1753 81.5878 75.8347C81.5847 81.8764 81.552 81.6686 74.3627 81.9438C69.9111 82.1143 67.6408 81.302 67.8757 76.0313C68.2982 66.5533 67.995 57.0429 67.995 47.0565Z"
      />
      <path
        d="M181.604 16.011C181.604 20.8359 181.688 25.1663 181.576 29.4916C181.502 32.347 182.453 33.8405 185.553 33.8199C196.373 33.7478 207.195 33.7285 218.014 33.8315C221.433 33.864 222.205 32.1762 222.163 29.1654C222.05 21.1764 222.239 13.1824 222.072 5.19529C221.999 1.70929 222.886 -0.0201991 226.796 0.0627349C237.702 0.294077 236.165 -0.79594 236.189 9.351C236.241 31.658 236.105 53.966 236.291 76.2715C236.328 80.6158 235.076 82.515 230.64 81.903C229.333 81.7227 227.971 81.7711 226.651 81.895C223.09 82.2291 222.018 80.5234 222.076 77.1589C222.223 68.6714 221.888 60.1704 222.22 51.6934C222.398 47.1602 220.737 46.0078 216.467 46.1208C206.485 46.3849 196.49 46.3279 186.505 46.1408C182.852 46.0724 181.448 47.2261 181.54 50.9883C181.745 59.4733 181.462 67.9701 181.665 76.4553C181.762 80.4913 180.525 82.0769 176.237 81.9702C167.402 81.7503 167.396 81.9708 167.396 72.9616C167.396 50.6545 167.388 28.3474 167.402 6.04032C167.406 0.12193 167.443 0.304595 174.74 0.0568297C179.178 -0.0938333 182.35 0.31875 181.67 6.03352C181.299 9.15228 181.604 12.3514 181.604 16.011Z"
      />
      <path
        d="M99.543 1.79105C101.28 0.973227 102.803 0.195433 104.33 0.185639C118.825 0.092704 133.322 0.279811 147.813 0.0427719C152.399 -0.0322451 153.065 2.14369 152.918 5.87006C152.779 9.43132 153.469 13.0402 147.899 12.9278C137.74 12.7227 127.573 12.9654 117.412 12.8145C114.196 12.7667 112.827 13.813 112.969 17.101C113.134 20.9259 113.238 24.781 112.946 28.5907C112.612 32.9512 114.58 33.9788 118.556 33.8712C127.046 33.6412 135.558 34.0554 144.041 33.7067C149.232 33.4933 149.037 36.4218 149.075 40.0311C149.114 43.7363 149.087 46.4992 143.977 46.2944C135.328 45.9477 126.651 46.3224 117.991 46.1404C114.389 46.0647 112.76 47.172 112.956 50.9711C113.178 55.2915 113.187 59.6419 112.953 63.961C112.745 67.8029 114.033 69.3054 118.067 69.2076C127.892 68.9696 137.733 69.3682 147.552 69.0353C152.898 68.8541 152.976 71.7899 152.93 75.6663C152.886 79.3157 152.933 82.0802 147.779 81.974C133.29 81.6755 118.791 81.8235 104.296 81.9068C101.2 81.9246 99.094 81.3745 99.1102 77.6452C99.2192 52.4927 99.2647 27.34 99.543 1.79105Z"
      />
      <path
        d="M252.807 35.9999C252.807 25.5022 252.968 15.5006 252.729 5.50854C252.632 1.4267 253.806 -0.485069 258.079 0.10811C259.386 0.289672 260.753 0.253313 262.071 0.11421C265.885 -0.28821 266.95 1.54402 266.917 5.11865C266.787 19.2809 266.832 33.4452 266.892 47.6085C266.903 50.2576 267.001 52.9624 267.533 55.5434C269.666 65.873 275.353 70.4611 285.622 70.4123C295.561 70.3651 301.371 65.7738 303.193 55.6313C304.067 50.7704 303.925 45.7 303.966 40.7221C304.065 28.8923 304.121 17.059 303.929 5.23148C303.865 1.27205 305.266 -0.316712 309.169 0.10999C310.651 0.272039 312.176 0.251465 313.663 0.113902C317.236 -0.216669 318.269 1.51166 318.236 4.85593C318.075 21.0156 318.319 37.183 317.93 53.3359C317.502 71.1415 304.952 82.9309 287.017 83.0022C264.492 83.0917 252.827 71.4972 252.808 48.9969C252.804 44.8313 252.807 40.6656 252.807 35.9999Z"
      />
    </svg>
  );
};
