import { useEffect, useState } from "react";
import {
  AlertInput,
  IonAccordion,
  IonAccordionGroup,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  useIonAlert,
} from "@ionic/react";
import {
  allQueuesState,
  leaveLine,
  notTodayQueueState,
  providerState,
  useGetProvider,
} from "apis";
import { Button, Header, Loading } from "components";
import { RouteComponentProps } from "react-router";
import { useRecoilValue } from "recoil";
import { getInLine } from "apis";
import { db } from "../../App";
import "./calendar.css";
import { userState } from "../../recoil/userState";
import moment from "moment";
import { BookType, IQueue } from "interfaces";
import Slot from "../../components/Slot/Slot";
import { peopleCircle, peopleOutline } from "ionicons/icons";
import GroupModal from "../../components/GroupModal/GroupModal";

const Calendar: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const user = useRecoilValue(userState);
  const provider = useRecoilValue(providerState);
  const queues = useRecoilValue(notTodayQueueState);
  const allQueues = useRecoilValue(allQueuesState);

  const [presentAlert] = useIonAlert();

  const [open, setOpen] = useState<boolean>(false);
  const [futureQueues, setFutureQueues] = useState<
    { date: string; queue: string | IQueue | null }[]
  >([]);
  const [currUserQueue, setCurrUserQueue] = useState(
    allQueues?.find((q) => q.line.some((r) => r.id === user?.id))
  );
  const [queue, setQueue] = useState<IQueue | string>();
  const [loading, setLoading] = useState(false);
  const [selectedService, setSelectedService] = useState<string>();
  const [GIL, setGIL] = useState(false);

  async function onGetInLineClick(queue: IQueue | null, date: string) {
    setLoading(true);
    if (provider && allQueues && user) {
      if (provider.services.length === 1)
        await getInLine({
          db,
          queues: allQueues,
          provider,
          queue: queue ? queue : date,
          customerId: user.id,
          data: {
            isConfirmed: false,
            timestamps: [],
            bookType: BookType.CALENDAR,
            hasStarted: false,
            customerId: user.id,
            date: queue === null ? date : queue.head.date,
            invite: null,
            party: {
              isApproved: true,
              numOfPeople: 1,
            },
            serviceId: provider.services[0].id,
            timeJoined: new Date(),
          },
        });
      else
        presentAlert({
          header: "Select a service",
          buttons: [
            { text: "Cancel", role: "cancel" },
            {
              text: "Get in line",
              role: "confirm",
              handler: () => {
                setGIL(true);
                // setTimeout(() => setGIL(false), 500);
              },
            },
          ],
          inputs: provider.services.map<AlertInput>((service) => ({
            type: "radio",
            label: service.title,
            value: service.id,
            handler: (input) => setSelectedService(input.value),
          })),
        });
    }
    setLoading(false);
  }
  async function onLeaveLineClick() {
    setLoading(true);
    if (provider && user && currUserQueue) {
      const confirmation = confirm("Are you sure you want to leave the line?");

      if (confirmation)
        await leaveLine({
          db,
          provider,
          queue: currUserQueue,
          customerId: user.id,
        });
    }
    setLoading(false);
  }

  useGetProvider(db, match.params.id);
  useEffect(() => {
    if (allQueues && user && provider && queues) {
      let lines = [];
      for (let i = 1; i <= provider.daysCanBookAhead; i++) {
        const date = moment().add(i, "days").format().split("T")[0];
        const getQueue = queues.find((q) => q.head.date === date);
        lines.push({
          date,
          queue: provider.hours[moment(date).day()]
            ? getQueue
              ? getQueue
              : null
            : "closed",
        });
      }
      setFutureQueues(lines);
    }
  }, [allQueues, provider, queues, user]);
  useEffect(() => {
    setCurrUserQueue(
      allQueues?.find((q) => q.line.some((r) => r.id === user?.id))
    );
  }, [allQueues, user?.id]);
  useEffect(() => {
    if (selectedService && provider && allQueues && user && queue && GIL) {
      getInLine({
        db,
        queues: allQueues,
        provider,
        queue: queue,
        customerId: user.id,
        data: {
          timestamps: [],
          bookType: BookType.CALENDAR,
          hasStarted: false,
          customerId: user.id,
          date: typeof queue === "string" ? queue : queue.head.date,
          invite: null,
          party: {
            isApproved: true,
            numOfPeople: 1,
          },
          serviceId: selectedService,
          timeJoined: new Date(),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GIL]);

  if (!allQueues || !provider || !user || !queues) return <Loading />;

  return (
    <IonPage>
      <Header label="Future lines" />
      <IonContent>
        <IonAccordionGroup>
          {futureQueues.map((queue) => {
            return (
              <IonAccordion
                key={queue.date}
                disabled={queue.queue === "closed"}
              >
                <IonItem slot="header">
                  <IonLabel>
                    {moment(queue.date).calendar({
                      nextDay: "[Tomorrow], MMM Do",
                      nextWeek: "dddd, MMM Do",
                      sameElse: "dddd, MMM Do",
                    })}
                    {queue.queue === "closed" && " (Closed)"}
                  </IonLabel>
                </IonItem>
                <div slot="content" className="content">
                  {!(
                    typeof queue.queue === "object" &&
                    queue.queue?.line.some((q) => q.id === user.id)
                  ) ? (
                    <>
                      {currUserQueue && "You are already in a line."}
                      <div className="btns">
                        <Button
                          expand
                          className="btn"
                          color="primary"
                          label="Get in line"
                          loading={loading}
                          disabled={loading || currUserQueue ? true : false}
                          onClick={() => {
                            setQueue(
                              queue.queue === null ? queue.date : queue.queue
                            );
                            onGetInLineClick(
                              queue.queue as IQueue | null,
                              queue.date
                            );
                          }}
                          size="small"
                        />
                        <Button
                          expand={false}
                          color="primary"
                          label={<IonIcon icon={peopleOutline} />}
                          loading={loading}
                          disabled={loading || currUserQueue ? true : false}
                          onClick={() => {
                            setQueue(
                              queue.queue === null ? queue.date : queue.queue
                            );
                            setOpen(true);
                          }}
                          size="small"
                        />
                      </div>
                    </>
                  ) : (
                    <Button
                      expand
                      color="medium"
                      label="Leave line"
                      disabled={loading}
                      onClick={() => {
                        onLeaveLineClick();
                      }}
                      size="small"
                    />
                  )}
                  {queue.queue && (queue.queue as IQueue).line?.length !== 0 ? (
                    (queue.queue as IQueue).line?.map((slot, index) => (
                      <Slot
                        db={db}
                        provider={provider}
                        queues={queues}
                        key={index}
                        index={index}
                        queue={queue.queue as IQueue}
                        slot={slot}
                        user={user}
                        pickSlotIsActive={false}
                      />
                    ))
                  ) : (
                    <p style={{ textAlign: "center" }}>No one in line yet.</p>
                  )}
                </div>
              </IonAccordion>
            );
          })}
        </IonAccordionGroup>
        <GroupModal
          setOpen={setOpen}
          open={open}
          provider={provider}
          allQueues={allQueues}
          user={user}
          queue={queue}
          bookType={BookType.CALENDAR}
        />
      </IonContent>
    </IonPage>
  );
};

export default Calendar;
