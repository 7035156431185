import {
  addDoc,
  arrayRemove,
  collection,
  doc,
  DocumentData,
  Firestore,
  getDocs,
  onSnapshot,
  query,
  QuerySnapshot,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  Book,
  Break,
  Head,
  IProvider,
  IQueue,
  IService,
  IUser,
  ProviderDTO,
  ServiceDTO,
} from "interfaces";
import moment from "moment";
import { useEffect } from "react";
import { atom, selector, useSetRecoilState } from "recoil";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FirebaseApp } from "@firebase/app";

export const providerState = atom<IProvider | undefined | null>({
  key: "provider",
  default: undefined,
});
export const allQueuesState = atom<IQueue[] | undefined>({
  key: "queue",
  default: undefined,
});
export const todayQueueState = selector({
  key: "todayQueue",
  get: ({ get }) => {
    if (get(allQueuesState) === undefined) return undefined;

    const today = get(allQueuesState)?.filter(
      (q) => q.head.id === moment().format().split("T")[0]
    )[0];

    return today ? today : "empty";
  },
});
export const notTodayQueueState = selector({
  key: "notTodayQueue",
  get: ({ get }) => {
    if (get(allQueuesState) === undefined) return undefined;

    return get(allQueuesState)?.filter(
      (q) => q.head.id !== moment().format().split("T")[0]
    );
  },
});
export const providerErrorState = atom<any>({
  key: "providerError",
  default: undefined,
});
export const queueErrorState = atom<any>({
  key: "queueError",
  default: undefined,
});

export const registerProvider = async (
  app: FirebaseApp,
  fetch: any,
  db: Firestore,
  data: ProviderDTO,
  services: ServiceDTO[],
  uid: string
) => {
  const resp = await fetch(data.profilePic);
  const blob = await resp.blob();
  const picRef = await uploadBytes(
    ref(getStorage(app), `profilePics/${uid}${Date.now()}`),
    blob
  );
  const url = await getDownloadURL(picRef.ref);

  await updateDoc(doc(db, "providers", uid), { ...data, profilePic: url });
  services.forEach((s) =>
    addDoc(collection(db, "providers", uid, "services"), s)
  );
};

export const useGetProvider = (db: Firestore, id: string | undefined, authStatus?: number[]) => {
  const setProvider = useSetRecoilState(providerState);
  const setQueue = useSetRecoilState(allQueuesState);
  const setProviderError = useSetRecoilState(providerErrorState);
  const setQueueError = useSetRecoilState(queueErrorState);

  useEffect(() => {
    if (id) {
      const unsubProvider = onSnapshot(doc(db, "providers", id), {
        next: async (provider) => {
          if ((provider.data() as IProvider)?.isComplete) {
            const servicesDocs = await getDocs(
              collection(db, "providers", id, "services")
            );
            const paymentMethods = await getDocs(
              query(
                collection(db, "paymentMethods"),
                where(
                  "__name__",
                  "in",
                  (provider.data() as IProvider).paymentMethodsIds
                )
              )
            );
            setProvider({
              id,
              ...provider.data(),
              services: servicesDocs.docs.map(
                (service) => ({ id: service.id, ...service.data() } as IService)
              ),
              paymentMethods: paymentMethods.docs.map((pm) => ({
                id: pm.id,
                ...pm.data(),
              })),
            } as IProvider);
          } else {
            setProvider(null);
            setProviderError(undefined);
          }
        },
        error: (err) => {
          setProvider(undefined);
          setProviderError(err);
        },
      });
      const unsubQueue = onSnapshot(collection(db, "providers", id, "queues"), {
        next: async (queues) => {
          // Get all queue heads
          const heads = queues.docs.filter((q) =>
            q.id.match(/\d\d\d\d-\d\d-\d\d/)
          );
          // Get each book for head's line
          const books = queues.docs.filter(
            (q) =>
              !heads.some((head) => head.id === q.id) &&
              q.data().bookType !== "break"
          );
          const serviceIds = books.map((q) => q.data().serviceId);
          const customerIds = books.map((q) =>
            q.data().customerId ? q.data().customerId : "null"
          );
          let services: QuerySnapshot<DocumentData>;
          let customers: QuerySnapshot<DocumentData>;

          if (serviceIds.length > 0 && customerIds.length > 0) {
            services = await getDocs(
              query(
                collection(db, "providers", id, "services"),
                where("__name__", "in", serviceIds)
              )
            );
            customers = await getDocs(
              query(
                collection(db, "users"),
                where("__name__", "in", customerIds)
              )
            );
          }
          const qs: IQueue[] = [];

          heads.forEach((q) => {
            const getLine = () => {
              const line: (Book | Break)[] = [];

              for (const x of (q.data() as Head).line) {
                const document = queues.docs.find((q) => q.id === x);

                if (document) {
                  const service =
                    (document.data() as Book | Break).bookType !== "break"
                      ? services.docs.find(
                          (r) => r.id === document.data().serviceId
                        )
                      : undefined;
                  const customer =
                    (document.data() as Book | Break).bookType !== "break"
                      ? customers.docs.find(
                          (r) => r.id === document.data().customerId
                        )
                      : undefined;
                  const data =
                    (document.data() as Book | Break).bookType === "break"
                      ? ({
                          id: document.id,
                          ...document.data(),
                        } as Break)
                      : ({
                          id: document.id,
                          ...document.data(),
                          service: {
                            id: service?.id,
                            ...service?.data(),
                          } as IService,
                          customer: !(document.data() as Book).customer
                            ? ({
                                id: customer?.id,
                                ...customer?.data(),
                              } as IUser)
                            : (document.data() as Book).customer,
                        } as Book);

                  line.push({ ...data });
                } else
                  updateDoc(doc(db, "providers", id, "queues", q.id), {
                    line: arrayRemove(x),
                  });
              }

              return line;
            };
            qs.push({
              head: { id: q.id, ...q.data() } as Head,
              line: [...getLine()],
            });
          });
          setQueue(qs);
          setQueueError(undefined);
        },
        error: (error) => {
          setQueue(undefined);
          setQueueError(error);
        },
      });

      return () => {
        unsubProvider();
        unsubQueue();
      };
    } else {
      setProvider(null);
      setQueue(undefined);
      setProviderError(undefined);
      setQueueError(undefined);

      return () => {};
    }
  }, [ db, id, setProvider, setProviderError, setQueue, setQueueError, authStatus]);
};
