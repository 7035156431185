import { IonSpinner } from "@ionic/react";

import "./loading.css";

export const Loading: React.FC<{}> = () => {
  return (
    <div className="container">
      <div className="spinner">
        <IonSpinner
          color="primary"
          name="crescent"
        ></IonSpinner>
      </div>
    </div>
  );
};
