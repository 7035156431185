/* eslint-disable @next/next/no-img-element */
import {
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonInput,
  IonTextarea,
  IonList,
  IonText,
  IonSelect,
  IonSelectOption,
  IonDatetime,
  IonModal,
  IonRadioGroup,
  IonDatetimeButton,
  IonRadio,
} from "@ionic/react";
import { Hours, IProvider, ProviderDTO } from "interfaces";
import { toTime } from "utilities";
import React, { useState } from "react";
import { add, pencil, remove } from "ionicons/icons";
import { updateDoc, Firestore, doc } from "firebase/firestore";
import { Modal } from "../Modal";
import { Button } from "../Button";
import {
  useGetPaymentMethods,
  paymentMethodsState,
  useGetProvider,
} from "../../../apis";
import { useRecoilValue } from "recoil";
import AutoComplete from "react-google-autocomplete";
import { GeoPoint } from "firebase/firestore";
import { E164Number } from "libphonenumber-js/types";
import PhoneInput, {
  formatPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Camera, CameraResultType, Photo } from "@capacitor/camera";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Loading } from "../Loading";
import { useEffect } from "react";
import moment from "moment";
import { FirebaseApp } from "@firebase/app";
import * as yup from "yup";

import "./detailsCard.css";

export interface DetailsCardProps {
  provider: IProvider;
  children: React.ReactNode;
  showEdit: boolean;
  db: Firestore;
  uid?: string;
  app?: FirebaseApp;
  fetch?: any;
}

export const DetailsCard: React.FC<DetailsCardProps> = ({
  provider,
  children,
  showEdit,
  db,
  uid,
  app,
  fetch,
}) => {
  const [value, setValue] = useState<Partial<ProviderDTO>>({});
  const [pic, setPic] = useState<Photo>();
  const [nameOpen, setNameOpen] = useState(false);
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [addressOpen, setAddressOpen] = useState(false);
  const [phoneOpen, setPhoneOpen] = useState(false);
  const [hoursOpen, setHoursOpen] = useState(false);
  const [paymentsOpen, setPaymentsOpen] = useState(false);
  const [addressSelected, setAddressSelected] = useState(true);
  const [hours, setHours] = useState([
    {
      isOpen: false,
      from: moment().set("hours", 8).set("minutes", 0).format(),
      to: moment().set("hours", 16).set("minutes", 0).format(),
      break: moment().set("hours", 12).set("minutes", 0).format(),
      breakMins: undefined as undefined | string,
      days: [] as number[],
    },
  ]);
  const [err, setErr] = useState<string>();

  const validateName = yup.object({
    name: yup.string().required(),
  });
  const validatePhone = yup.object({
    forUse: yup.string().min(9).required(),
  });
  const validateHours = yup.object({
    hours: yup.array().of(
      yup.object({
        isOpen: yup.boolean().required(),
        breakMins: yup.number().when("isOpen", {
          is: true,
          then: (schema) => schema.required(),
        }),
        days: yup.array().of(yup.number()).min(1).required(),
      })
    ),
  });
  const validatePayments = yup.object({
    paymentMethods: yup.array().min(1).of(yup.string()),
  });

  const paymentMethods = useRecoilValue(paymentMethodsState);

  const today = new Date().getDay();
  function displayTime(num: number) {
    if (!provider.hours[num]) return "Closed";

    return `${toTime(
      (provider.hours[num] as Hours).from.hour,
      (provider.hours[num] as Hours).from.mins
    )}-${toTime(
      (provider.hours[num] as Hours).to.hour,
      (provider.hours[num] as Hours).to.mins
    )}`;
  }
  async function updateField() {
    if (uid) await updateDoc(doc(db, "providers", uid), value);
  }
  function getHours(num: number) {
    const hour = hours.find((h) => h.days.includes(num));
    const fromTime = new Date(hour?.from || "");
    const toTime = new Date(hour?.to || "");

    return hour?.isOpen
      ? {
          from: {
            hour: fromTime.getHours(),
            mins: fromTime.getMinutes(),
          },
          to: {
            hour: toTime.getHours(),
            mins: toTime.getMinutes(),
          },
        }
      : null;
  }
  function getBreak(num: number) {
    const hour = hours.find((h) => h.days.includes(num));
    const breakTime = new Date(hour?.break || "");

    return hour?.isOpen
      ? {
          time: {
            hour: breakTime.getHours(),
            mins: breakTime.getMinutes(),
          },
          duration: parseInt(hour.breakMins || "0"),
        }
      : null;
  }
  async function getPhoto() {
    const img = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      allowEditing: true,
      width: 1,
      height: 1,
    });

    const resp = await fetch(img.webPath);
    const blob = await resp.blob();
    const picRef = await uploadBytes(
      ref(getStorage(app), `profilePics/${uid}${Date.now()}.${img.format}`),
      blob
    );
    const url = await getDownloadURL(picRef.ref);
    await updateDoc(doc(db, "providers", uid || ""), { profilePic: url });
  }

  useGetPaymentMethods(db);
  useEffect(() => {
    if (provider) {
      const hoursList: {
        isOpen: boolean;
        from: string;
        to: string;
        break: string;
        breakMins: undefined | string;
        days: number[];
      }[] = [];
      for (const key in provider.hours) {
        const hour = provider.hours[key];
        const pBreak = provider.break[key];
        const i = hoursList.findIndex((h) => {
          const from = moment(h.from);
          const to = moment(h.to);
          const breakTime = moment(h.break);

          return hour === null
            ? h.isOpen === false
            : h.isOpen === true &&
                hour.from.hour === from.get("hours") &&
                hour.from.mins === from.get("minutes") &&
                hour.to.hour === to.get("hours") &&
                hour.to.mins === to.get("minutes") &&
                pBreak?.time.hour === breakTime.get("hours") &&
                pBreak?.time.mins === breakTime.get("minutes") &&
                pBreak?.duration === pBreak.duration;
        });
        if (i !== -1) {
          hoursList[i].days.push(parseInt(key));
        } else {
          hoursList.push({
            isOpen: hour !== null,
            from: moment()
              .set("hours", hour?.from.hour || 8)
              .set("minutes", hour?.from.mins || 0)
              .format(),
            to: moment()
              .set("hours", hour?.to.hour || 16)
              .set("minutes", hour?.to.mins || 0)
              .format(),
            break: moment()
              .set("hours", pBreak?.time.hour || 12)
              .set("minutes", pBreak?.time.mins || 0)
              .format(),
            breakMins: pBreak?.duration.toString(),
            days: [parseInt(key)] as number[],
          });
        }
      }
      setHours(hoursList);
    }
  }, [provider]);

  if (!paymentMethods) return <Loading />;

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol sizeLg="3" className="img details-col">
            <div>
              <img
                className="details-profilePic"
                src={provider.profilePic}
                alt={`${provider.name}'s profile picture`}
              />
              {showEdit && (
                <IonButton
                  fill="clear"
                  size="small"
                  onClick={() => {
                    getPhoto();
                    setValue({ profilePic: provider.profilePic });
                  }}
                >
                  <IonIcon slot="icon-only" icon={pencil} />
                </IonButton>
              )}
            </div>
          </IonCol>
          <IonCol className="details-col">{children}</IonCol>
        </IonRow>
      </IonGrid>

      <div style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
        <div className="edit-container">
          <h1>{provider.name}</h1>
          {showEdit && (
            <IonButton
              fill="clear"
              size="small"
              onClick={() => {
                setValue({ name: provider.name });
                setNameOpen(true);
              }}
            >
              <IonIcon slot="icon-only" icon={pencil} />
            </IonButton>
          )}
        </div>

        <div className="edit-container">
          <p>{provider.description}</p>
          {showEdit && (
            <IonButton
              fill="clear"
              size="small"
              onClick={() => {
                setValue({ description: provider.description });
                setDescriptionOpen(true);
              }}
            >
              <IonIcon slot="icon-only" icon={pencil} />
            </IonButton>
          )}
        </div>

        <div className="edit-container">
          <h5 className="h5">Address:</h5>
          {showEdit && (
            <IonButton
              fill="clear"
              size="small"
              onClick={() => {
                setValue({
                  address: {
                    city: provider.address.city,
                    coords: provider.address.coords,
                    state: provider.address.state,
                    street: provider.address.street,
                    suite: provider.address.suite,
                    zip: provider.address.zip,
                  },
                });
                setAddressOpen(true);
              }}
            >
              <IonIcon slot="icon-only" icon={pencil} />
            </IonButton>
          )}
        </div>
        <p className="no-margin">{provider.address.street}</p>
        {provider.address.suite === "" ? null : (
          <p className="no-margin">{provider.address.suite}</p>
        )}
        <p className="no-margin">{`${provider.address.city}, ${provider.address.state} ${provider.address.zip}`}</p>

        <div className="edit-container">
          <h5 className="h5">Phone number:</h5>
          {showEdit && (
            <IonButton
              fill="clear"
              size="small"
              onClick={() => {
                setValue({
                  phone: {
                    formatted: provider.phone.formatted,
                    forUse: provider.phone.forUse,
                  },
                });
                setPhoneOpen(true);
              }}
            >
              <IonIcon slot="icon-only" icon={pencil} />
            </IonButton>
          )}
        </div>
        <a href={`tel:${provider.phone.forUse}`}>{provider.phone.formatted}</a>

        <div className="edit-container">
          <h5 className="h5">Hours:</h5>
          {showEdit && (
            <IonButton
              fill="clear"
              size="small"
              onClick={() => {
                setHoursOpen(true);
              }}
            >
              <IonIcon slot="icon-only" icon={pencil} />
            </IonButton>
          )}
        </div>
        <IonAccordionGroup>
          <IonAccordion value="hours">
            <IonItem slot="header">
              <IonLabel>
                <p>{`Today: ${displayTime(today)}`}</p>
                <p>{`Tomorrow: ${displayTime(
                  today + 1 === 7 ? 0 : today + 1
                )}`}</p>
              </IonLabel>
            </IonItem>
            <div slot="content">
              <p>{`Sunday: ${displayTime(0)}`}</p>
              <p>{`Monday: ${displayTime(1)}`}</p>
              <p>{`Tuesday: ${displayTime(2)}`}</p>
              <p>{`Wednesday: ${displayTime(3)}`}</p>
              <p>{`Thursday: ${displayTime(4)}`}</p>
              <p>{`Friday: ${displayTime(5)}`}</p>
              <p>{`Saturday: ${displayTime(6)}`}</p>
            </div>
          </IonAccordion>
        </IonAccordionGroup>

        <div className="edit-container">
          <h5 className="h5">Payment Methods:</h5>
          {showEdit && (
            <IonButton
              fill="clear"
              size="small"
              onClick={() => {
                setValue({
                  paymentMethodsIds: provider.paymentMethodsIds,
                });
                setPaymentsOpen(true);
              }}
            >
              <IonIcon slot="icon-only" icon={pencil} />
            </IonButton>
          )}
        </div>
        <IonAccordionGroup>
          <IonAccordion value="payments">
            <IonItem slot="header">
              <IonLabel>
                {provider.paymentMethods.map((p) => p.label).join(", ")}
              </IonLabel>
            </IonItem>
            <div slot="content">
              {provider.paymentMethods.map((method) => (
                <p key={method.id}>{method.label}</p>
              ))}
            </div>
          </IonAccordion>
        </IonAccordionGroup>
      </div>

      {/* Name modal */}
      <Modal isOpen={nameOpen} setOpen={setNameOpen} title="Edit">
        {err && (
          <IonText color="danger">
            <p>{err}</p>
          </IonText>
        )}
        <IonItem>
          <IonLabel position="stacked">Name</IonLabel>
          <IonInput
            value={value.name}
            onIonInput={(e) => setValue({ name: e.target.value as string })}
          ></IonInput>
        </IonItem>
        <Button
          expand
          label="Save"
          color="primary"
          onClick={async () => {
            setErr(undefined);
            try {
              await validateName.validate(value);
              await updateField();
              setNameOpen(false);
            } catch (error) {
              setErr("Please enter valid name.");
            }
          }}
        />
      </Modal>
      {/* Description modal */}
      <Modal isOpen={descriptionOpen} setOpen={setDescriptionOpen} title="Edit">
        <IonItem>
          <IonLabel position="stacked">Description</IonLabel>
          <IonTextarea
            value={value.description}
            onIonInput={(e) =>
              setValue({ description: e.target.value as string })
            }
          ></IonTextarea>
        </IonItem>
        <Button
          expand
          label="Save"
          color="primary"
          onClick={async () => {
            await updateField();
            setDescriptionOpen(false);
          }}
        />
      </Modal>
      {/* Address modal */}
      <Modal isOpen={addressOpen} setOpen={setAddressOpen} title="Edit">
        {err && (
          <IonText color="danger">
            <p>{err}</p>
          </IonText>
        )}
        <IonList>
          <IonItem className="ion-invalid" lines="full">
            <IonLabel position="stacked">Address</IonLabel>
            <AutoComplete
              id="street"
              placeholder="Enter street"
              style={{
                width: "100%",
                backgroundColor: "rgba(0,0,0,0)",
                border: "none",
              }}
              options={{ types: ["address"] }}
              defaultValue={`${provider.address.street}, ${provider.address.city}, ${provider.address.state} ${provider.address.zip}`}
              // eslint-disable-next-line turbo/no-undeclared-env-vars
              apiKey={process.env.REACT_APP_googleMapAPI}
              onPlaceSelected={(place) => {
                setValue({
                  address: {
                    street: (place.formatted_address as string).split(",")[0],
                    city: place.address_components[3].long_name,
                    state: place.address_components[5].short_name,
                    zip: place.address_components[7].long_name,
                    coords: new GeoPoint(
                      place.geometry.location?.lat() || 0,
                      place.geometry.location?.lng() || 0
                    ),
                    suite: "",
                  },
                });
                setAddressSelected(true);
              }}
              onChange={() => {
                setAddressSelected(false);
              }}
            />
            {!addressSelected && (
              <IonText color="danger" style={{ fontSize: 13 }}>
                <p>Please select an address from dropdown.</p>
              </IonText>
            )}
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="floating">Address 2</IonLabel>
            <IonInput
              value={value.address?.suite}
              name="suite"
              placeholder="Suite, apt, unit..."
              onIonChange={(e) =>
                setValue({
                  address: {
                    ...value.address,
                    suite: e.target.value as string,
                  } as any,
                })
              }
            ></IonInput>
          </IonItem>
        </IonList>
        <Button
          expand
          label="Save"
          color="primary"
          onClick={async () => {
            if (addressSelected) {
              await updateField();
              setAddressOpen(false);
            }
          }}
        />
      </Modal>
      {/* Phone modal */}
      <Modal isOpen={phoneOpen} setOpen={setPhoneOpen} title="Edit">
        {err && (
          <IonText color="danger">
            <p>{err}</p>
          </IonText>
        )}
        <IonItem>
          <IonLabel position="stacked">Enter your phone number:</IonLabel>
          <PhoneInput
            name="phone"
            placeholder="(000) 000-0000"
            className="phone-input"
            defaultCountry="US"
            value={value.phone?.forUse}
            onChange={(num) => {
              setValue({
                phone: {
                  forUse: num === undefined ? "" : num,
                  formatted:
                    num === undefined
                      ? ""
                      : `${
                          parsePhoneNumber(num as string)?.countryCallingCode
                        } ${formatPhoneNumber(num as E164Number)}`,
                },
              });
            }}
          />
        </IonItem>
        <Button
          expand
          label="Save"
          color="primary"
          onClick={async () => {
            setErr(undefined);
            try {
              await validatePhone.validate(value.phone);
              await updateField();
              setPhoneOpen(false);
            } catch (error) {
              setErr("Please enter valid phone number.");
            }
          }}
        />
      </Modal>
      {/* Hours modal */}
      <Modal isOpen={hoursOpen} setOpen={setHoursOpen} title="Edit">
        {err && (
          <IonText color="danger">
            <p>{err}</p>
          </IonText>
        )}
        {hours.map((hour, index) => (
          <div key={index}>
            <IonList>
              <IonRadioGroup
                allowEmptySelection={false}
                value={hour.isOpen}
                onIonChange={(e) => {
                  setHours(
                    hours.map((r, i) =>
                      i === index ? { ...r, isOpen: e.target.value } : r
                    )
                  );
                }}
              >
                <IonItem>
                  <IonRadio slot="start" value={false}></IonRadio>
                  <IonLabel>Closed</IonLabel>
                </IonItem>
                <IonItem lines="none">
                  <IonRadio slot="start" value={true}></IonRadio>
                  <IonLabel>Open</IonLabel>
                </IonItem>
              </IonRadioGroup>
              <IonItem disabled={!hours[index].isOpen} lines="none">
                <IonItem lines="none">
                  <p>From:</p>
                  <IonDatetimeButton
                    datetime={`from${index}`}
                  ></IonDatetimeButton>
                </IonItem>
                <IonItem lines="none">
                  <p>To:</p>
                  <IonDatetimeButton
                    datetime={`to${index}`}
                  ></IonDatetimeButton>
                </IonItem>
              </IonItem>
              <IonItem disabled={!hours[index].isOpen}>
                <IonItem lines="none">
                  <p>Break: </p>
                  <IonDatetimeButton
                    datetime={`break${index}`}
                  ></IonDatetimeButton>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Duration:</IonLabel>
                  <IonInput
                    value={hour.breakMins}
                    placeholder="in mins"
                    type="number"
                    onIonChange={(e) =>
                      setHours(
                        hours.map((r, i) =>
                          i === index
                            ? {
                                ...r,
                                breakMins:
                                  e.target.value === ""
                                    ? undefined
                                    : (e.target.value as undefined),
                              }
                            : r
                        )
                      )
                    }
                  ></IonInput>
                </IonItem>
              </IonItem>
              <IonItem className="ion-invalid" lines="full">
                <IonLabel>Select days</IonLabel>
                <IonSelect
                  value={hours[index].days}
                  onIonChange={(e) => {
                    setHours(
                      hours.map((r, i) =>
                        i === index ? { ...r, days: e.target.value } : r
                      )
                    );
                  }}
                  multiple
                >
                  <IonSelectOption value={0}>Sunday</IonSelectOption>
                  <IonSelectOption value={1}>Monday</IonSelectOption>
                  <IonSelectOption value={2}>Tuesday</IonSelectOption>
                  <IonSelectOption value={3}>Wednesday</IonSelectOption>
                  <IonSelectOption value={4}>Thursday</IonSelectOption>
                  <IonSelectOption value={5}>Friday</IonSelectOption>
                  <IonSelectOption value={6}>Saturday</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonList>

            <IonModal keepContentsMounted={true}>
              <IonDatetime
                value={hours[index].from}
                onIonChange={(e) =>
                  setHours(
                    hours.map((r, i) =>
                      i === index ? { ...r, from: e.target.value as string } : r
                    )
                  )
                }
                id={`from${index}`}
                presentation="time"
              ></IonDatetime>
            </IonModal>
            <IonModal keepContentsMounted={true}>
              <IonDatetime
                value={hours[index].to}
                onIonChange={(e) =>
                  setHours(
                    hours.map((r, i) =>
                      i === index ? { ...r, to: e.target.value as string } : r
                    )
                  )
                }
                id={`to${index}`}
                presentation="time"
              ></IonDatetime>
            </IonModal>
            <IonModal keepContentsMounted={true}>
              <IonDatetime
                value={hours[index].break}
                onIonChange={(e) =>
                  setHours(
                    hours.map((r, i) =>
                      i === index
                        ? { ...r, break: e.target.value as string }
                        : r
                    )
                  )
                }
                id={`break${index}`}
                presentation="time"
              ></IonDatetime>
            </IonModal>
          </div>
        ))}
        <div className="horizontal-btns">
          {hours.length > 1 && (
            <IonButton
              fill="clear"
              onClick={() =>
                setHours(hours.filter((_, i) => i !== hours.length - 1))
              }
            >
              <IonIcon slot="icon-only" icon={remove}></IonIcon>
            </IonButton>
          )}
          {hours.length < 7 && (
            <IonButton
              fill="clear"
              onClick={() => {
                setHours([
                  ...hours,
                  {
                    isOpen: false,
                    from: moment().set("hours", 8).set("minutes", 0).format(),
                    to: moment().set("hours", 16).set("minutes", 0).format(),
                    break: moment().set("hours", 12).set("minutes", 0).format(),
                    breakMins: undefined,
                    days: [],
                  },
                ]);
              }}
            >
              <IonIcon slot="icon-only" icon={add}></IonIcon>
            </IonButton>
          )}
        </div>
        <Button
          expand
          label="Save"
          color="primary"
          onClick={async () => {
            try {
              await validateHours.validate(value);
              await updateDoc(doc(db, "providers", uid || ""), {
                hours: {
                  0: getHours(0),
                  1: getHours(1),
                  2: getHours(2),
                  3: getHours(3),
                  4: getHours(4),
                  5: getHours(5),
                  6: getHours(6),
                },
                break: {
                  0: getBreak(0),
                  1: getBreak(1),
                  2: getBreak(2),
                  3: getBreak(3),
                  4: getBreak(4),
                  5: getBreak(5),
                  6: getBreak(6),
                },
              });
              setHoursOpen(false);
            } catch (error) {
              setErr("Please check that all fields are valid.");
            }
          }}
        />
      </Modal>
      {/* Payments modal */}
      <Modal isOpen={paymentsOpen} setOpen={setPaymentsOpen} title="Edit">
        {err && (
          <IonText color="danger">
            <p>{err}</p>
          </IonText>
        )}
        <IonItem className="ion-invalid" lines="full">
          <IonSelect
            value={value.paymentMethodsIds}
            name="paymentMethods"
            onIonChange={(e) => {
              setValue({ paymentMethodsIds: e.target.value });
            }}
            placeholder="Select all payment methods"
            multiple
          >
            {paymentMethods.map((paymentMethod) => (
              <IonSelectOption key={paymentMethod.id} value={paymentMethod.id}>
                {paymentMethod.label}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <Button
          expand
          label="Save"
          color="primary"
          onClick={async () => {
            try {
              await validatePayments.validate(value);
              await updateField();
              setPaymentsOpen(false);
            } catch (error) {
              setErr("Please select at least 1 method.");
            }
          }}
        />
      </Modal>
    </>
  );
};
