import { atom, useSetRecoilState } from "recoil";
import { onSnapshot, doc } from "firebase/firestore";
import { User } from "firebase/auth";
import { auth, db } from "../App";
import { useEffect } from "react";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { userErrorState, userState } from "./userState";

import { IUser } from "interfaces";
import { Capacitor } from "@capacitor/core";

export const authState = atom<boolean | undefined>({
  key: "auth",
  default: undefined,
});
export const authErrorState = atom<Error | undefined>({
  key: "authError",
  default: undefined,
});

export const useAuth = () => {
  const setAuth = useSetRecoilState(authState);
  const setAuthError = useSetRecoilState(authErrorState);
  const setUserData = useSetRecoilState(userState);
  const setUserError = useSetRecoilState(userErrorState);

  useEffect(() => {
    const unsub = auth.onAuthStateChanged(
      (user) => {
        if (user) {
          setAuth(true);
          setAuthError(undefined);
          onSnapshot(doc(db, "users", user.uid), {
            next: (snap) => {
              if (snap.exists())
                setUserData({ id: user?.uid, ...snap.data() } as IUser);
              else setUserData(null);
              setUserError(undefined);
            },
            error: (error) => {
              setUserData(undefined);
              setUserError(error);
            },
          });
        } else {
          setAuth(false);
          setAuthError(undefined);
          setUserData(null);
        }
      },
      (error) => {
        setAuth(undefined);
        setAuthError(error);
        setUserData(undefined);
      }
    );

    return () => unsub();
  }, [setAuth, setAuthError, setUserData, setUserError]);
};
