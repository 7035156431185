import { useEffect, useRef, useState } from "react";
import {
  AlertInput,
  IonButton,
  IonButtons,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonSlide,
  IonSlides,
  IonText,
  useIonAlert,
  useIonRouter,
} from "@ionic/react";
import { Button, Modal } from "components";
import { addOutline, arrowBackOutline, removeOutline } from "ionicons/icons";
import { Clipboard } from "@capacitor/clipboard";
import "./groupModal.css";
import { getInLine } from "apis";
import {
  IQueue,
  BookType,
  IProvider,
  IUser,
  InviteDTO,
  Party,
} from "interfaces";
import { db } from "../../App";
import moment from "moment";

interface ModalProps {
  pageRef?: React.MutableRefObject<HTMLIonContentElement | undefined>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  provider: IProvider;
  allQueues: IQueue[] | undefined;
  queue: IQueue | string | undefined;
  user: IUser;
  bookType: BookType;
}

const GroupModal = ({
  provider,
  allQueues,
  user,
  queue,
  bookType,
  open,
  setOpen,
  pageRef,
}: ModalProps) => {
  const [selected, setSelected] = useState<"party" | "invite">("party");
  const [numOfPeople, setNumOfPeople] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedService, setSelectedService] = useState<string>();
  const [token, setToken] = useState<string>("");
  const [link, setLink] = useState(`https://mehu.app/details/${provider.id}`);
  const [GIL, setGIL] = useState(false);

  const [presentAlert] = useIonAlert();

  const slideRef = useRef<HTMLIonSlidesElement>();

  function generateToken() {
    const t = Date.now().toString();

    setToken(t);
    return t;
  }

  async function onGetInLineClick(invite: InviteDTO | null, party: Party) {
    setLoading(true);
    if (provider && allQueues && user && queue) {
      if (provider.services.length === 1)
        await getInLine({
          db,
          queues: allQueues,
          provider,
          queue: queue === "empty" ? moment().format().split("T")[0] : queue,
          customerId: user.id,
          data: {
            timestamps: [],
            hasStarted: false,
            bookType,
            customerId: user.id,
            date:
              queue === "empty"
                ? moment().format().split("T")[0]
                : typeof queue === "string"
                ? queue
                : queue.head.date,
            invite,
            party,
            serviceId: provider.services[0].id,
            timeJoined: new Date(),
          },
        });
      else
        presentAlert({
          header: "Select a service",
          inputs: provider.services.map<AlertInput>((service) => ({
            type: "radio",
            label: service.title,
            value: service.id,
            handler: (input) => setSelectedService(input.value),
          })),
          buttons: [
            { text: "Cancel", role: "cancel" },
            {
              text: "Get in line",
              role: "confirm",
              handler: async () => {
                setGIL(true);
                setTimeout(() => setGIL(false), 500);
                setTimeout(() => pageRef?.current?.scrollToBottom(750), 750);
              },
            },
          ],
        });
    }
    setLoading(false);
  }

  useEffect(() => {
    if (selectedService && provider && allQueues && user && queue && GIL) {
      getInLine({
        db,
        queues: allQueues,
        provider,
        queue: queue === "empty" ? moment().format().split("T")[0] : queue,
        customerId: user.id,
        data: {
          timestamps: [],
          bookType,
          hasStarted: false,
          customerId: user.id,
          date:
            queue === "empty"
              ? moment().format().split("T")[0]
              : typeof queue === "string"
              ? queue
              : queue.head.date,
          invite: null,
          party: {
            isApproved: numOfPeople <= 3,
            numOfPeople: numOfPeople + 1,
          },
          serviceId: selectedService,
          timeJoined: new Date(),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GIL]);

  return (
    <>
      <Modal isOpen={open} setOpen={setOpen} title="Group add">
        <IonSlides
          options={{
            allowTouchMove: false,
          }}
          ref={slideRef as React.Ref<HTMLIonSlidesElement>}
        >
          <IonSlide>
            <div style={{ width: "90%" }}>
              <IonList>
                <IonRadioGroup
                  value={selected}
                  onIonChange={(value) => {
                    setSelected(value.target.value);
                    setNumOfPeople(1);
                  }}
                >
                  <IonItem>
                    <IonLabel class="ion-text-wrap">
                      <h1>One party group</h1>
                      <p>
                        For those with children or with people without a smart
                        phone (Adding limited).
                      </p>
                    </IonLabel>
                    <IonRadio slot="end" value="party"></IonRadio>
                  </IonItem>
                  <IonItem disabled>
                    <IonLabel class="ion-text-wrap">
                      <h1>Invite friends (coming soon)</h1>
                      <p>
                        Invite your friends (with or without an account) and
                        reserve their spots for 4 mintues.
                      </p>
                    </IonLabel>
                    <IonRadio slot="end" value="invite"></IonRadio>
                  </IonItem>
                </IonRadioGroup>
              </IonList>
              <Button
                expand
                label="Next"
                color="primary"
                onClick={() => slideRef.current?.slideNext()}
              />
            </div>
          </IonSlide>

          <IonSlide>
            <div style={{ width: "100%" }}>
              <IonButtons slot="start">
                <IonButton onClick={() => slideRef.current?.slidePrev()}>
                  <IonIcon slot="icon-only" icon={arrowBackOutline}></IonIcon>
                </IonButton>
              </IonButtons>
              {selected === "party" && <p>Number of people with you</p>}
              {selected === "invite" && (
                <p>Number of friends you want to invite</p>
              )}
              <span className="counter">
                <IonButton
                  disabled={numOfPeople <= 1}
                  onClick={() => setNumOfPeople(numOfPeople - 1)}
                >
                  <IonIcon slot="icon-only" icon={removeOutline}></IonIcon>
                </IonButton>
                <h1>{numOfPeople}</h1>
                <IonButton
                  disabled={numOfPeople >= 9 && selected === "party"}
                  onClick={() => setNumOfPeople(numOfPeople + 1)}
                >
                  <IonIcon slot="icon-only" icon={addOutline}></IonIcon>
                </IonButton>
              </span>
              {numOfPeople > 3 && selected === "party" && (
                <IonText color="danger">
                  <p>
                    For the provider&apos;s convenience, your spot will need to
                    be approved first (your spot will be reserved in the
                    meantime).
                  </p>
                </IonText>
              )}
              {selected === "party" && (
                <Button
                  expand
                  loading={loading}
                  disabled={loading}
                  label="Get in line"
                  color="primary"
                  onClick={async () => {
                    await onGetInLineClick(null, {
                      isApproved: numOfPeople < 3,
                      numOfPeople: numOfPeople + 1,
                    });
                    setOpen(false);
                    setTimeout(
                      () => pageRef?.current?.scrollToBottom(750),
                      750
                    );
                  }}
                />
              )}
              {selected === "invite" && (
                <Button
                  expand
                  label="Continue"
                  color="primary"
                  onClick={async () => {
                    await onGetInLineClick(
                      {
                        count: 0,
                        timestamp: new Date(),
                        numOfPeople,
                        token: generateToken(),
                      },
                      {
                        isApproved: true,
                        numOfPeople: 1,
                      }
                    );
                    setLink(`${link}?token=${token}&userId=${user.id}`);
                    slideRef.current?.slideNext();
                  }}
                />
              )}
            </div>
          </IonSlide>

          <IonSlide>
            <div style={{ width: "90%" }}>
              <h1>Copy link:</h1>
              <div>
                <IonInput value={link} readonly></IonInput>
                <Button
                  color="primary"
                  size="small"
                  label="Copy"
                  expand={false}
                  onClick={() => Clipboard.write({ url: link })}
                />
              </div>
              <h3>Time remaining:</h3>
              <h1>4:00</h1>
            </div>
          </IonSlide>
        </IonSlides>
      </Modal>
    </>
  );
};

export default GroupModal;
